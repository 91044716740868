<script>
import { defineComponent } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatPhone } from '/~/utils/format/string'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DefaultDrawerPersonalDetails from '/~/components/ewallet-menu/views/profile-settings/profile-personal-details.vue'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import DrawerAddress from '/~/templates/australiapost/components/drawer/views/personal-details/components/drawer-address.vue'

export default defineComponent({
  components: {
    BaseAsidePage,
    BaseIcon,
    DrawerAddress,
  },
  extends: DefaultDrawerPersonalDetails,
  setup() {
    const { marketingCommsEmail } = useUser()
    const {
      userMetadataMarketingCommsEmail,
      profileConfigForm,
      isUserAddressEnabled,
      isProfileFormEditable,
    } = useProvider()

    return {
      ...DefaultDrawerPersonalDetails.setup?.(...arguments),
      userMetadataMarketingCommsEmail,
      marketingCommsEmail,
      profileConfigForm,
      isUserAddressEnabled,
      isProfileFormEditable,
    }
  },
  computed: {
    info() {
      const {
        firstName,
        lastName,
        startDate,
        email,
        mobile,
        username,
        dob: birthDate,
        sex,
      } = this.profileConfigForm

      return [
        this.getItem(firstName, 'First name', this.user.firstName),
        this.getItem(lastName, 'Last name', this.user.lastName),
        this.getItem(
          startDate,
          'Start date',
          formatDate('daymonthyearnumeric', this.user.startDate)
        ),
        {
          title:
            this.userMetadataMarketingCommsEmail?.label ?? 'Marketing email',
          value: this.marketingCommsEmail,
          isVisible: Boolean(this.userMetadataMarketingCommsEmail),
        },
        this.getItem(email, 'Email', this.user.email),
        this.getItem(mobile, 'Mobile', formatPhone(this.user.mobile)),
        this.getItem(username, 'User name', this.user.username),
        this.getItem(
          birthDate,
          'Date of birth',
          formatDate('daymonthyearnumeric', this.user.birthDate)
        ),
        this.getItem(sex, 'Gender', this.user.sexText),
      ]
    },
  },
})
</script>

<template>
  <base-aside-page title="Personal Details">
    <template v-if="profileConfigForm.isVisible">
      <div class="mb-[30px] space-y-5">
        <div class="flex items-center justify-between">
          <span class="font-bold text-eonx-neutral-600">Information</span>
          <button
            v-if="isProfileFormEditable"
            class="flex flex-row items-center text-primary"
            @click="onEditProfile"
          >
            <span class="mr-[5px]">Edit</span>
            <base-icon svg="plain/edit2" :size="16" />
          </button>
        </div>
        <template v-for="(item, index) of info">
          <div
            v-if="item.isVisible"
            :key="index"
            class="rounded-xl bg-gray-50 px-6 py-5"
          >
            <div class="text-eonx-neutral-600">
              {{ item.title }}
            </div>
            <div class="font-bold text-eonx-neutral-600">
              {{ item.value || '-' }}
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-if="isUserAddressEnabled">
      <div class="mt-5 flex items-center justify-between">
        <span class="font-bold text-eonx-neutral-600">Address</span>
        <button
          class="flex flex-row items-center text-primary"
          @click="onAddAddress"
        >
          <span class="mr-[5px]">Add</span>
          <base-icon svg="plus" :size="20" />
        </button>
      </div>
      <base-loader v-if="isAddressesLoading" fullwidth class="mt-5" />
      <div v-else-if="isAddressesEmpty" class="mt-5 flex items-center">
        <base-image src="address-illustration.svg" class="mx-5 w-1/3" />

        <div>
          <div class="mb-[5px] text-xl font-bold">No address stored</div>
          <div>Add your address for a speedy checkout process.</div>
        </div>
      </div>
      <template v-else>
        <div v-for="address of sortedAddresses" :key="address.id" class="mt-5">
          <drawer-address :address="address" />
        </div>
      </template>
    </template>
  </base-aside-page>
</template>
